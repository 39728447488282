form .emailField {
    background-color: #1080E0;
    color: white;
}

form .emailField:focus {
    outline: none;
    color:white;
    box-shadow: 0px 0px 5px white;/*here change the color*/
    border:1px solid white;/*here change the color*/
    background-color: #1080E0;
}

form .emailField::placeholder {
    color: white;
    background-color: #1080E0;

}

form .submitButton {
    color: white;
    background-color: #E87390;
}

form .submitButton:hover {
    color: white;
    background-color: #E87390;
    border-color: white;
}

form .hidden {
    visibility: hidden;
}

#success {
    visibility: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align:right;
    font-style: italic
}