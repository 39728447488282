.App {
  text-align: center;
  background-color: #0d67b5;
  color: white;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

footer {
  min-height: 10vh;
}



.col2 {
  width: 50%;
  float: left;
  vertical-align: text-top;
}